import React from 'react';
import Header from './atoms/Header';

const ContactSection = (props) => {

    return (
        <section className="relative h-screen bg-cover bg-center justify-center items-center" style={{ backgroundImage: `url(${props.banner})` }}>
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="absolute inset-0 flex justify-center items-center">
                <div className="bg-green-800 bg-opacity-75 p-8 rounded-lg justify-center items-center">
                    <form>
                        <div className="mb-4">
                            <div className='flex mb-4'>
                                <Header text={'Sign Up for our Quarterly Bulletin'} hrColor={'transparent'} color={'white'} />
                            </div>
                            <div className='flex mb-4'>
                                <p className='font-serif text-white leading-5 tracking-widest'>Receive updates on new projects, current developments, sales offers and discounts <br /> from cleave</p>
                            </div>
                            <div className="flex mb-4">
                                <input type="text" className="bg-transparent border-b border-green-400 focus:border-green-600 flex-1 mr-2 px-3 py-2 placeholder-gray-300 text-white" placeholder="First Name" />
                                <input type="text" className="bg-transparent border-b border-green-400 focus:border-green-600 flex-1 ml-2 px-3 py-2 placeholder-gray-300 text-white" placeholder="Last Name" />
                            </div>
                            <div className="flex mb-4">
                                <input type="tel" className="bg-transparent border-b border-green-400 focus:border-green-600 flex-1 mr-2 px-3 py-2 placeholder-gray-300 text-white" placeholder="Phone" />
                                <input type="email" className="bg-transparent border-b border-green-400 focus:border-green-600 flex-1 ml-2 px-3 py-2 placeholder-gray-300 text-white" placeholder="Email" />
                            </div>
                            <div className='flex mb-4'>
                                <button type="submit" className="bg-white hover:bg-green-700 text-green-800 font-bold py-2 px-2.5 rounded-xl focus:outline-none focus:shadow-outline">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default ContactSection;
