import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaArrowAltCircleRight } from 'react-icons/fa';

interface Property {
    id: number;
    developmentName: string;
    developmentLocation: string;
    developmentDescription: string;
    developmentHero: string | undefined;
}

interface ImageSliderProps {
    data: Property[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({ data }) => {
    const options = {
        loop: true,
        margin: 10,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            }
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };


        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const renderCarousel = (item: {
        developmentName: string;
        developmentLocation: string;
        developmentDescription: string;
        developmentHero: string | undefined;
      
    }) => {
        const textDiv = (
            <div className='leading-4'>
                <h1 className="text-5xl text-green-700">{item.developmentName}</h1>
                <p className="text-gray-500 font-serif">Location: {item.developmentLocation}</p>
                <p className='text-green-700 pt-10 font-extrabold'>{item.developmentDescription}</p>
                <div className='mt-3'>
                <Link to={`/property/${item.developmentName}`} className="bg-green-800 text-white mt-2 rounded-xl px-2.5 py-1.5 text-xs font-bold"> 
                
                <FaArrowAltCircleRight size={18} className='mr-2' />
                        Explore   {item.developmentName}
    
                </Link>
                       
                   
                </div>
            </div>
        )
    
        const imgDiv = (
            <div>
                <img
                    src={item.developmentHero}
                    alt={item.developmentName}
                    className="w-full h-auto"
                />
            </div>
        )
    
        return (
            <>
                {isMobile ? [imgDiv, textDiv] : [textDiv, imgDiv]}
            </>
        );
    }
    


    return (
        <div className="lg:col-span-4 flex flex-col justify-center items-center">
            <OwlCarousel className="owl-theme" {...options}>
                {data.map((item) => (
                    <div key={item.id} className="grid grid-cols-1 md:grid-cols-2 gap-4 py-10">
                        {renderCarousel(item)}
                    </div>
                ))}
            </OwlCarousel>
            
        </div>
    );
};

export default ImageSlider;
