import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="bg-white text-grey-800 py-8">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-4 ">
                    {/* Column 1: About Our Company */}
                    <div>
                        <h4 className="text-lg mb-4 font-bold">About our company</h4>
                        <p className='text-sm'>Cleave is a property development company<br/> specialize in building affordable aesthetic and <br></br>functional homes for modern families.</p>
                    </div>

                    {/* Column 2: Administrative Links */}
                    <div>
                        <h4 className="text-lg font-bold mb-4">Administrative Links</h4>
                        <ul className='text-sm'>
                            <li><a href="#">Brochure</a></li>
                            <li><a href="#">Investor Interest Forum</a></li>
                            <li><a href="#">Buyer Interest Forum </a></li>
                        </ul>
                    </div>

                    {/* Column 3: Development Links */}
                    <div>
                        <h4 className="text-lg font-bold mb-4">Development</h4>
                        <ul className='text-sm'>
                            <li><a href="#">Spencer</a></li>
                            <li><a href="#">Eko Ile</a></li>
                            <li><a href="#">Omode</a></li>
                        </ul>
                    </div>

                    {/* Column 4: Social Media Icons */}
                    <div>
                        <h4 className="text-lg font-bold mb-4 text-green-800">Follow Us</h4>
                        <div className="flex ">
                            <FaInstagram size={30} />
                            <FaLinkedin size={30} />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
