// Description.jsx
import React from 'react';

const Description = () => {
    return (
        <div className="md:w-1/2 sm:w-1 lg:w-1/2 text-white text-center mb-8 px-2">
            {/* Description Paragraph */}
            <p className="text-lg">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
            </p>
        </div>
    );
};

export default Description;
