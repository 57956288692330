// DevelopmentComponent.jsx
import React, {useState, useEffect} from 'react';
import ImageSlider from './atoms/ImageSlider';
import Header from './atoms/Header';
import background from '../assets/banner.png'



const Development = (props) => {

    console.log(props.developments)

    

    return (
        <>
            {/* <div className='max-w-[1440px] mx-auto grid lg:grid-cols-4 h-screen gap-4 px-2 py-8' style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className='lg:col-span-2 flex flex-col'>
                    <div>
                        <h2>Our Developments</h2>
                        <hr className="w-24 border-t-2 border-green-500 px-2 mt-2" />

                    </div>
                    <div className='grid sm:grid-cols-2 gap-8 py-4'>

                    </div>
                </div>

                <div className='lg:col-span-2'>

                </div>
                <ImageSlider data={propertyData}/>
            </div> */}
            <section className='max-w-[1440px] mx-auto lg:h-screen px-2 py-8' style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className='grid lg:grid-cols-4'>
                    <div className='lg:col-span-3'>
                        <Header color={`black`} text={`Our Development`} hrColor={`green`} />
                        <p className='py-4 font-serif leading-5 text-gray-700'>
                            Buying a property off-plan or during construction guarantees you multiples<br /> 
                            on you investments immediately the project is completed. Explore our on-going<br />
                            development projects below.
                        </p>
                    </div>
                    
                </div>
                <ImageSlider data={props.developments} />              
            </section>
        </>
    );
};

export default Development;
