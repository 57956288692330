import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { imageIcons} from '../state';
import Header from './atoms/Header';
import Description from './atoms/Description';
import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: " https://cleaves.code14-labs.tech/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    })

const ConceptProps = () => {
    const params = useParams()
    const navigate = useNavigate();
    const { electricity, parking, security, fitness } = useSnapshot(imageIcons);
    const [developmentName , setDevelopmentName] = useState("")
    const [developmentDescription , setDevelopmentDescription] = useState("")
    const [secure , setSecure] = useState("")
    const [electric, setElectric] = useState("")
    const [gym, setGym] = useState("")
    const [park, setPark] = useState("")
    const [devScope, setDevScope] = useState([])
    const [devImages, setDevImages] = useState([])
    const [developmentHero, setDevelopmentHero] = useState([])
    

 
    
  


    client
    .query({
      query: gql`
      query{
           pages {
                edges {
                  node {
              
                    concepts {
                        conceptList {
                          ammenitiesElectricity
                          ammenitiesFitness
                          ammenitiesParking
                          ammenitiesSecurity
                          developmentDescription
                          developmentHero {
                            mediaItemUrl
                          }
                          developmentImages {
                            images{
                              mediaItemUrl
                            }
                          }
                      
                          developmentName
                          developmentScope {
                            floorplan
                            roomnumber
                            scopeImage{
                              mediaItemUrl
                            }
                          }
                        }
                      }
                  }
                }
              }
    }
      `,
    })
    .then(async result => { 
  
          console.log(result.data.pages.edges[0].node.concepts.conceptList)
          
     
          // setboardLamContent(result.data.pages.edges[1].node.develop.developmentList)


           for( let i=0; i <= result.data.pages.edges[0].node.concepts.conceptList.length - 1; i++){
             console.log(params.id)
             console.log(params.id.length)
             console.log(result.data.pages.edges[0].node.concepts.conceptList[i].developmentName)
             
           let  devName = result.data.pages.edges[0].node.concepts.conceptList[i].developmentName
         
              
               if (devName === params.id){
                 console.log("rann")

                 setDevelopmentName(result.data.pages.edges[0].node.concepts.conceptList[i].developmentName)
                 setDevelopmentDescription(result.data.pages.edges[0].node.concepts.conceptList[i].developmentDescription)
                 setDevelopmentHero(result.data.pages.edges[0].node.concepts.conceptList[i].developmentHero.mediaItemUrl)
                 setDevScope(result.data.pages.edges[0].node.concepts.conceptList[i].developmentScope)
                 setDevImages(result.data.pages.edges[0].node.concepts.conceptList[i].developmentImages)
                 setSecure(result.data.pages.edges[0].node.concepts.conceptList[i].ammenitiesSecurity)
                 setGym(result.data.pages.edges[0].node.concepts.conceptList[i].ammenitiesFitness)
                 setElectric(result.data.pages.edges[0].node.concepts.conceptList[i].ammenitiesElectricity)
                 setPark(result.data.pages.edges[0].node.concepts.conceptList[i].ammenitiesParking)
           
                 return true
                
               }

                }
    })


    

    const imageHeight = `calc(60vh - 4rem)`;

    return (
        <>
            <section>
                {/* Banner with property title and description */}
                <div className="bg-green-800 text-white py-4 px-6">
                    <h2 className="text-2xl font-bold">Welcome to {developmentName}</h2>
                    <p className="text-sm"></p>
                </div>

                {/* Main image */}
                <img src={developmentHero} alt={developmentName} className="w-full h-[60vh] object-cover" />

                {/* Image icons with captions */}
                <div className="flex justify-center items-center md:justify-evenly my-6">
                 
                { electric && (    <div className="icon-container bg-white px-2 py-4 shadow-2xl rounded-md flex flex-col justify-center items-center w-14 h-14" id={`secure ? show : bad `}>
                            <img src={electricity} alt="Electricity" className="icon h-7 pb-2" />
                            <p className="caption font-serif text-xs text-green-800">Electricity</p>
                        </div>
                )}
                  
                  { park && ( 
                        <div className="icon-container bg-white px-2 py-4 shadow-2xl rounded-md flex flex-col justify-center items-center w-14 h-14">
                            <img src={parking} alt="Parking" className="icon h-7 pb-2" />
                            <p className="caption font-serif text-xs text-green-800">Parking</p>
                        </div>
                  )}
                   
                   { secure && ( 
                        <div className="icon-container bg-white px-2 py-4 shadow-2xl rounded-md flex flex-col justify-center items-center w-14 h-14">
                            <img src={security} alt="Security" className="icon h-7 pb-2" />
                            <p className="caption font-serif text-xs text-green-800">Security</p>
                        </div>
                   )} 
                  
                  { gym && ( 
                        <div className="icon-container bg-white px-2 py-4 shadow-2xl rounded-md flex flex-col justify-center items-center w-14 h-14">
                            <img src={fitness} alt="Fitness" className="icon h-7 pb-2" />
                            <p className="caption font-serif text-xs text-green-800">Fitness</p>
                        </div>
                  )}
                </div>

            </section>
            <section className='py-5'>
                <div className='mx-2'>
                    <Header text={`Want to live at ${developmentName} ?`} color={`green-800`} hrColor={`green-800`} align={`center`} />
                    <p className="text-sm text-center"></p>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-4">
                        {devScope.map((plan, index) => (
                            <div key={index} className="bg-white rounded-2xl mx-4 shadow-md">
                                <img src={plan.scopeImage.mediaItemUrl} alt="" className="w-full h-auto rounded-t-lg" />
                                <div className="rounded-t-md bg-green-900 flex flex-col justify-center items-center">
                                    <p className="text-sm text-white"> {plan.roomnumber} bedroom Apartment</p>
            
                                    <a href={plan.floorplan} className="border border-white text-white text-xs rounded-full px-2 py-1 mb-2 transition duration-300 hover:bg-white hover:text-green-800">
                                        See Floor Plan
                                    </a>
                                </div>

                            </div>
                        ))}
                    </div>

                </div>

            </section>

            <section className='mt-5'>
                    <Header text={`Experience ${developmentName}'s Aesthetics`} color={`green-800`} hrColor={`transparent`} align={`center`} />
                    <div className="flex flex-wrap justify-center">
                        {devImages.map((images, index) => (
                            <div key={index} className="w-1/2">
                                <img src={images.images.mediaItemUrl} alt="" className="w-full h-[80vh] object-cover" />

                                {/* <img src={image.image} alt={image.caption} className="w-full h-70vh object-cover"  /> */}
                                {/* <p className="text-center">{image.caption}</p> */}
                            </div>
                        ))}
                    </div>

            </section>
        </>
    );
}

export default ConceptProps;
