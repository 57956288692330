import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Home from './components/Home';
import DevelopmentPage from './components/DevelopementPage';
import Property from './components/Property';
import Sustainability from './components/Sustainability';
import Bulletin from './components/Bulletin';
import SingleBulletin from './components/SingleBulletin';
import Concept from './components/concepts';
import ConceptProps from './components/conceptProps';


function App() {
	return (
		<>
			<Router>
				<Navigation />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/development" element={<DevelopmentPage />} />
					<Route path="/property/:id" element={<Property />} />
					<Route path="/concept" element={<Concept />} />
					<Route path="/concept/property/:id" element={<ConceptProps />} />
					<Route path="/sustainability" element={<Sustainability />} />
					<Route path="/bulletin" element={<Bulletin />} />
					<Route path="/bulletin/:id" element={<SingleBulletin />} />
				</Routes>
				<Footer />
			</Router>
		</>
	);
}

export default App;
