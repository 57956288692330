import React, { useState, useEffect } from 'react';

import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: " https://cleaves.code14-labs.tech/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    })


const Sustainability = () => {

    const [sustainabilityHero , setSustainabilityHero]  = useState("")
    const [sustainabilityMessage , setSustainabilityMessage]  = useState("")


    useEffect(() => {



        client
        .query({
          query: gql`
          query{
          pages {
            edges {
              node {
                sustainability {
                    heroImage {
                      mediaItemUrl
                    }
                    statement
                  }
              }
            }
          }
        }
          `,
        })
        .then(async result => { 
     
              console.log(result.data.pages.edges[0].node.sustainability)   
              setSustainabilityHero(result.data.pages.edges[0].node.sustainability.heroImage.mediaItemUrl)  
              setSustainabilityMessage(result.data.pages.edges[0].node.sustainability.statement)   
     
          
            
        })

    })

    return (
        <section>
            {/* Main image */}
            <img src={sustainabilityHero} alt={`cleave Sustainability`} className="w-full h-[70vh] object-cover object-top" />
            <h2 className='text-green-800 font-serif tracking-widest text-3xl font-normal m-5 text-center'>Read our sustainability statement</h2>
            <div className='mx-5 px-5 text-gray-500 font-serif'>
                <p className='mb-4'>
                 {sustainabilityMessage}
                </p>
        
            </div>

        </section>
    )
}

export default Sustainability