import React from 'react'

type Props = {
    color: String,
    text: String,
    hrColor: String
    align?: String,
}

const Header = ({ color, text, hrColor, align = 'left' }: Props) => {
    return (
        <div className={`text-${color} text-${align} text-3xl font-bold text-left mb-4 tracking-wides items-center`}>
            {text}
            <hr className={`w-24 h-4 border-t-4 align-middle border-${hrColor}-500 mx-5 my-2`} />
        </div>
    )
}

export default Header