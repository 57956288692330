import React, {useState, useEffect} from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { bulletinState } from '../state';


import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: " https://cleaves.code14-labs.tech/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    })

const SingleBulletin = () => {

    const [devPackb , setDevPackb] = useState([]);

    const params = useParams()

    const [bulletinName, setBulletinName] = useState("")
    const [bulletinContentA, setBulletinContentA] = useState("")
    const [bulletinHero, setBulletinHero] = useState("")
    const [bulletinContentB, setBulletinContentB] = useState("")
    const [bulletinImages, setBulletinImages] = useState("")
    const [bulletinDate, setBulletinDate] = useState("")

    useEffect( () => {

        client
    .query({
      query: gql`
      query($id:ID!) {
        bulletinBy(id:$id) {
            date
            bulletin {
              bulletinContent
              bulletinName
              bulletinImage {
                mediaItemUrl
              }
              bulletinTextContd
              bulletinUpdates {
                image1 {
                  mediaItemUrl
                }
              }
            }
          }
  }
   
      `,
      variables: {id:params.id}
      })
    .then(async result => {
  
       console.log(result.data.bulletinBy.bulletin)

       setBulletinName(result.data.bulletinBy.bulletin.bulletinName)
       setBulletinContentA(result.data.bulletinBy.bulletin.bulletinContent)
       setBulletinImages(result.data.bulletinBy.bulletin.bulletinImage.mediaItemUrl)
       setBulletinContentB(result.data.bulletinBy.bulletin.bulletin)
       setBulletinDate(result.data.bulletinBy.date)
        
       
  
     
    })
  
})
   

    return (
        <section className='max-w-[1440px] mx-auto px-2 py-1'>
            <div className='grid lg:grid-cols-4'>
                <div className='lg:col-span-4 mt-14 items-center justify-items-center'>
                    <div className="text-center font-bold text-2xl tracking-widest font-serif text-green-800">{bulletinName}</div>
                    <p className='py-4 font-serif leading-5 text-gray-500 text-center'>
                        published on {bulletinDate}
                    </p>
                    <img src={bulletinImages} alt="" className='h-[60vh] w-full object-cover px-11 pt-4' />
                    <div className='font-serif text-gray-600 tracking-widest leading-6 px-11 py-10 my-10'>
 
                          {bulletinContentA}
                 </div>
                </div>
            </div>
        </section>
    )
}

export default SingleBulletin