// Projects.jsx
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import background from '../assets/banner.png'
import OwlCarousel from 'react-owl-carousel';
import Header from './atoms/Header';
import Description from './atoms/Description';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: " https://cleaves.code14-labs.tech/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    })


// Sample projects data (replace with your actual data)

const Projects = (props) => {

    const [devPackb , setDevPackb] = useState([]);
    

  client
  .query({
    query: gql`
    query{
    pages {
      edges {
        node {
          concepts {
            conceptList {
              developmentHero {
                mediaItemUrl
              }
              developmentLocation
              developmentName
          
            }
          }
        }
      }
    }
  }
    `,
  })
  .then(async result => { 
    let obj
       console.log(result.data.pages.edges[0].node.concepts.conceptList)        
       //setConceptList(result.data.pages.edges[0].node.concepts.conceptList) 
      
       if(devPackb.length <= 0 ){ 
       for(let x=0 ; x <= result.data.pages.edges[0].node.concepts.conceptList.length - 1; x++ ){ 
            console.log('loopes')
         obj = {
           developmentName: result.data.pages.edges[0].node.concepts.conceptList[x].developmentName,
           developmentLocation: result.data.pages.edges[0].node.concepts.conceptList[x].developmentLocation,
           developmentDescription: result.data.pages.edges[0].node.concepts.conceptList[x].developmentDescription,
           developmentHero: result.data.pages.edges[0].node.concepts.conceptList[x].developmentHero.mediaItemUrl
             }     
                        
                 devPackb.push(obj)

        } 
  
     }
         
  
  
  console.log(devPackb)            
  })
    
    const [isMobile, setIsMobile] = useState(false);
    const [overlayHeight, setOverlayHeight] = useState('60vh')

    useEffect(() => {

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            (window.innerWidth <= 768) ? setOverlayHeight('70vh') : setOverlayHeight('60vh');
        };


        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const imageHeight = `calc(${overlayHeight} - 4rem)`;
    const buttonHeight = 8;
   if(devPackb.length){
    return (
        <div className="relative h-screen">
            <div className="absolute inset-0" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-x-0 top-0 bottom-0 bg-green-800 bg-opacity-90" style={{ height: overlayHeight }}></div>
            </div>
            <div className="absolute inset-0 flex flex-col justify-center items-center text-white md:mt-3 sm:mt-2">
                <Header color={`white`} text={`Endorse your Favorite Concept`} hrColor={`white`} />
                <Description />
                {isMobile ? (
                    <OwlCarousel className="owl-theme" loop margin={10} items={1} dotClass="owl-dot" dotsEach>
                        {devPackb.map(project => (
                            <div key={project.id} className="item flex justify-center items-center bg-white rounded-lg shadow-md" style={{ marginTop: '2vh' }}>
                                <div className="flex flex-col items-center">
                                    <img src={project.developmentHero} alt="" className="w-full rounded-t-lg" style={{ maxHeight: imageHeight }} />
                                    <div className="flex flex-col items-center mt-4 docs">
                                        <h3 className="text-2xl text-gray-800 font-extrabold">{project.developmentName}</h3>
                                        <p className="text-sm text-gray-800 font-extrabold">{project.developmentLocation}</p>
                                        <Link to={`/concept/property/${project.developmentName}`} className="bg-green-800 text-white mt-2 rounded-xl px-2.5 py-1.5 text-xs font-bold"> Endorse </Link>
                                        <a href="#" className="text-green-800 mt-1 py-2 font-serif text-sm">Read Project Plan</a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                
                ) : (
                    <div className="overflow-y-auto">
                        <div className="flex justify-center items-end mt-auto ">
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                {devPackb.map(project => (
                                    <div key={project.id} className="flex flex-col items-center bg-white rounded-lg mx-10 shadow-md">
                                        <img src={project.developmentHero} alt="" className="w-full rounded-t-lg" style={{ maxHeight: imageHeight }} />
                                        <div className="flex flex-col items-center mt-4">
                                            <h3 className="text-2xl text-gray-800 font-extrabold">{project.developmentName}</h3>
                                            <p className="text-sm text-gray-800 font-bold font-extra-bold">{project.develomentLocation}</p>
                                            <Link to={`/concept/property/${project.developmentName}`} className="bg-green-800 text-white mt-2 rounded-xl px-2.5 py-1.5 text-xs font-bold"> Endorse </Link>   <a href="#" className="text-green-800 mt-1 py-2 font-serif text-sm">Read Project Plan</a>
                                        </div>
                                    </div>
                                ))}
                            </div>  
                        </div>
                    </div>
                )}

<div className='m-5' style={{ maxHeight: `${buttonHeight}vh` }}>
<Link to="/concept" className="bg-green-800 text-white mt-2 rounded-xl px-2.5 py-1.5 text-xs font-bold"> See all concepts </Link>
                </div>
            </div>
        </div>
    );
                                }
};

export default Projects;